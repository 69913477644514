<template>
  <el-card>
    <el-form
        ref="addActivityRef"
        :model="postsForm"
        label-width="80px">
      <el-form-item :label="$t('page.console.menu.investor')">
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="postsForm.investor"></el-image>
          <el-input slot="reference" v-model="postsForm.investor" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('investor')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <el-form-item :label="$t('page.console.menu.technical_support')">
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="postsForm.technicalSupport"></el-image>
          <el-input slot="reference" v-model="postsForm.technicalSupport" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('technicalSupport')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <el-form-item :label="$t('page.console.menu.media')">
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="postsForm.media"></el-image>
          <el-input slot="reference" v-model="postsForm.media" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('media')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <el-form-item>
        <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="false"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import {updateOption, getOption} from '@/api/os/admin';

export default {
  data() {
    return {
      optionName: 'page-banner',
      dialogVisible: false,
      type: '',
      index: 0,
      postsForm: {
        investor: '',
        technicalSupport: '',
        media: ''
      }
    }
  },
  components: {
    selectImage
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.postsForm = res.data.data
        }
      })
    },
    onSave() {
      updateOption({
        name: this.optionName,
        value: this.postsForm
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    },
    mediaUpload(type,index = 0) {
      this.dialogVisible = true
      this.index = index
      this.type = type
    },
    getImages(data) {
      this.postsForm[this.type] = data
      this.dialogVisible = false
    },
  }
}
</script>